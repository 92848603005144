import React, { useState, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Bot } from 'lucide-react';
import { AlertDialog, AlertDialogContent, AlertDialogHeader, AlertDialogTitle, AlertDialogDescription, AlertDialogFooter, AlertDialogAction } from './components/ui/alert-dialog';
import Navigation from './components/Navigation';
import { siteConfig } from './config';

// Lazy load pages for better performance
const Home = lazy(() => import('./pages/Home'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const TermsOfService = lazy(() => import('./pages/TermsOfService'));
const CheckoutPage = lazy(() => import('./pages/CheckoutPage'));

const Loading = () => (
  <div className="min-h-screen flex items-center justify-center bg-gray-900 interactive-bg">
    <div className="space-y-4 text-center glass-card p-8 rounded-lg">
      <Bot className="w-12 h-12 text-indigo-500 floating" />
      <p className="text-white text-lg gradient-text">Loading amazing features...</p>
      <div className="cyberpunk-border w-48 h-2 bg-gray-800 rounded-full overflow-hidden">
        <div className="h-full bg-indigo-500 animate-pulse" style={{width: '60%'}} />
      </div>
    </div>
  </div>
);

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showCookieConsent, setShowCookieConsent] = useState(true);
  const [currency, setCurrency] = useState('GBP');
  const [showContact, setShowContact] = useState(false);

  React.useEffect(() => {
    setTimeout(() => setIsLoading(false), 1500);
  
    const consentGiven = localStorage.getItem('cookieConsent');
    if (consentGiven) setShowCookieConsent(false);
  }, []);

  const handleCookieConsent = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShowCookieConsent(false);
  };

  if (isLoading) return <Loading />; 

  return (
    <Router>
      <div className="min-h-screen bg-gray-900">
        <Navigation 
        currency={currency} 
        setCurrency={setCurrency}
        setShowContact={setShowContact}
        />

        {/* Main Content */}
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/" element={<Home currency={currency} setCurrency={setCurrency} />} />
            <Route path="/checkout" element={<CheckoutPage currency={currency} setCurrency={setCurrency} />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsOfService />} />
          </Routes>
        </Suspense>

        {/* Alert Dialog */}
        <AlertDialog open={showContact} onOpenChange={setShowContact}>
          <AlertDialogContent className="bg-gray-900 border border-gray-800">
            <AlertDialogHeader>
              <AlertDialogTitle className="text-2xl text-white">Contact Me</AlertDialogTitle>
              <AlertDialogDescription className="text-gray-400">
                <div className="space-y-4">
                  <p>Contact me through any of these:</p>
                  <ul className="space-y-2">
                    <li className="flex items-center space-x-2">
                      <span>Discord:</span>
                      <span className="text-indigo-400">{siteConfig.contact.discord}</span>
                    </li>
                    <li className="flex items-center space-x-2">
                      <span>Email:</span>
                      <span className="text-indigo-400">{siteConfig.contact.email}</span>
                    </li>
                  </ul>
                </div>
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogAction 
                className="bg-indigo-600 hover:bg-indigo-700 text-white"
                onClick={() => setShowContact(false)}
              >
                Got it, thanks!
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>

        {/* Cookie Consent Banner */}
        {showCookieConsent && (
          <div className="fixed bottom-0 w-full bg-gray-800 text-white py-4 px-6 flex items-center justify-between">
            <p className="text-sm">
              We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies.
            </p>
            <button 
              onClick={handleCookieConsent}
              className="ml-4 px-4 py-2 bg-indigo-600 rounded-md hover:bg-indigo-700 transition-colors duration-300"
            >
              Accept
            </button>
          </div>
        )}
      </div>
    </Router>
  );
};

export default App;