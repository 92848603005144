import { Shield, Zap, Code } from 'lucide-react';

export const siteConfig = {
  // Site general
  siteName: "Storption",
  heroTitle: "Next-Gen Discord Bots",
  heroDescription: "Transform your Discord server with custom-built bots featuring cutting-edge technology",

  currencies: {
    GBP: {
      symbol: '£'
    },
    USD: {
      symbol: '$'
    }
  },

  // Menu Items
  menuItems: [],

  // Features section
  features: [
    {
      icon: Shield,
      title: 'Advanced Security',
      description: 'Protect your server with state-of-the-art moderation tools'
    },
    {
      icon: Zap,
      title: 'Lightning Fast',
      description: 'Optimized performance with rapid response times'
    },
    {
      icon: Code,
      title: 'Custom Development',
      description: 'Tailor-made solutions for your unique needs'
    }
  ],

  // Stats section
  stats: {
    title: "Our Impact",
    items: [
      { icon: 'Server', value: '1', label: 'Active Bots' },
      { icon: 'Users', value: '1', label: 'Users Served' },
      { icon: 'Coffee', value: '99.9%', label: 'Uptime' }
    ]
  },

  // Pricing section
  pricing: {
    title: "Simple Pricing",
    description: "The perfect plan for your needs",
    plans: [
      {
        name: "My Service",
        prices: {
          GBP: {
            monthly: "5.50",
            yearly: "66.00"
          },
          USD: {
            monthly: "7.00",
            yearly: "86.00"
          }
        },
        features: [
          "Get a completely custom Bot from me and i'll do whatever you'd like with it (with in reason of course). And i mean anything though want a ticket system implemented? done! auto moderation system? done! ANYTHING? DONE! Get it ill make the best bot for your needs/requirements without restrictions!"
        ],
        buttonText: "Get Started",
        gradient: "from-indigo-500 via-purple-500 to-pink-500",
        buttonClass: "bg-indigo-600 hover:bg-indigo-700"
      },
      {
        name: "Supporter Service",
        prices: {
          GBP: {
            monthly: "15.00",
            yearly: "180.00"
          },
          USD: {
            monthly: "19.00",
            yearly: "229.00"
          }
        },
        features: [
          "Everything in My Service, plus:",
          "Priority support and updates",
          "This is basically just if you'd like to be extra supportive and help out my future developing career. Obviously there's not much more you'd get by using this service instead other then priority but this is mainly just to be extra supportive and if you do decide to use this service i thank you very very much!"
        ],
        buttonText: "Be Extra Supportive",
        gradient: "from-purple-500 via-pink-500 to-indigo-500",
        buttonClass: "bg-purple-600 hover:bg-purple-700"
      }
    ]
  },

  // FAQ section
  faq: {
    title: "Frequently Asked Questions",
    description: "Find answers to common questions about our services",
    questions: [
      {
        question: "How long does it take to set up a custom bot?",
        answer: "Most custom bots can be set up within 24-48 hours. Complex implementations may take longer."
      }
    ]
  },

  // CTA section
  cta: {
    title: "Ready to Transform Your Server?",
    description: "Be apart of satisfied server owners who elevate their Discord communities with my custom bots.",
    buttonText: "Get Started Now"
  },

  // Contact Info
  contact: {
    discord: "Storption",
    email: "storption.business@gmail.com"
  }
};